import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./App.css";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingSpinner from "./components/LoadingSpinner";

// Lazy load components and pages
const Hero = lazy(() => import("./components/Hero"));
const Visuals = lazy(() => import("./components/Visuals"));
const Reviews = lazy(() => import("./components/Reviews"));
const Registered = lazy(() => import("./components/Registered"));
const PartneredSites = lazy(() => import("./components/PartneredSites"));
const WraparoundProgram = lazy(() => import("./components/WraparoundProgram"));
const JobDetail = lazy(() => import("./components/JobDetail"));

const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const Courses = lazy(() => import("./pages/Courses"));
const Jobs = lazy(() => import("./pages/Jobs"));
const WraparoundPrograms = lazy(() => import("./pages/WraparoundPrograms"));

// Lazy load course pages
const ComputingBasics = lazy(() => import("./pages/courses/ComputingBasics"));
const Coding = lazy(() => import("./pages/courses/Coding"));
const Graphic = lazy(() => import("./pages/courses/Graphic"));
const Science = lazy(() => import("./pages/courses/Science"));
const FinanceFundamentals = lazy(() =>
  import("./pages/courses/FinanceFundamentals")
);
const AcademicSupport = lazy(() => import("./pages/courses/AcademicSupport"));

const HomePage = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <Hero />
    <WraparoundProgram />
    <Visuals />
    <Registered />
    <Reviews />
    <PartneredSites />
  </Suspense>
);

const courseRoutes = [
  { path: "foundations-of-computing", Element: ComputingBasics },
  { path: "coding-and-game-development", Element: Coding },
  { path: "graphic-design", Element: Graphic },
  { path: "science-math", Element: Science },
  { path: "finance-fundamentals", Element: FinanceFundamentals },
  { path: "academic-support", Element: AcademicSupport },
];

function App() {
  return (
    <Router>
      <Helmet>
        <title>Home - Edu Plus Care</title>
      </Helmet>
      <Navbar />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/wraparound" element={<WraparoundPrograms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/jobs/:jobId" element={<JobDetail />} />
          {courseRoutes.map(({ path, Element }) => (
            <Route key={path} path={`/courses/${path}`} element={<Element />} />
          ))}
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
